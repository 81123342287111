<template>
  <div
    class="co-flex-col pa-4"
  >
    <v-row>
      <template
        v-if="items.length"
      >
        <v-col
          v-for="(item, i) in items"
          :key="`wk_${i}`"
          cols="12"
          md="4"
        >
          <v-hover
            v-slot="{ hover }"
            :disabled="!hovered"
            close-delay="120"
          >
            <v-card
              :elevation="hover ? hoverElevation : normalElevation"
              :class="[round, 'transition-swing']"
              :color="backColor"
              flat
              tile
            >
              <v-img
                :aspect-ratio="ratio"
                :src="item.cover"
                height="inherit"
                @click="toContent(i, item)"
              >
                <!-- <div class="co-flex-col co-justify-between co-h-full">
                  <div
                    class="co-flex-col co-w-full co-flex-grow "
                    @click="toContent(i, item)"
                  />
                  <div
                    class="co-flex-row co-justify-between co-items-center grey darken-4 co-h40 px-4"
                    style="opacity: 0.70;"
                  >
                    <span
                      class="text-body-1 white--text text-truncate"
                      @click="toContent(i, item)"
                    >
                      {{ item.title }}
                    </span>
                  </div>
                </div> -->
              </v-img>
              <div class="co-flex-col px-4 py-2">
                <div
                  class="text-uppercase co-text-sm co-text-line1"
                  style="line-height: 32px; cursor: pointer;"
                  @click="toContent(i, item)"
                >
                  {{ item.title }}
                </div>
                <div class="co-flex-row co-justify-between co-items-center my-2">
                  <div class="co-flex-row co-items-center">
                    <v-avatar
                      size="24"
                    >
                      <v-img
                        :src="item.user.avatar || defAvatar"
                      />
                    </v-avatar>
                    <span class="co-text-xs ml-2">{{ item.user.username }}</span>
                    <span class="co-text-xs ml-4 co-text-gray-500">{{ item.shortDate }}</span>
                  </div>
                  <div class="co-flex-row co-items-center">
                    <land-icon-like
                      name="like"
                      :value="item.likesNum"
                      @action="onAction($event, i, item)"
                    />
                    <land-icon-comment
                      name="comment"
                      enabled
                      :value="item.commentNum"
                      @action="onAction($event, i, item)"
                    />
                    <land-icon-forward
                      name="forward"
                      :value="item.forwardedNum"
                      margin="m0"
                      @action="onAction($event, i, item)"
                    />
                  </div>
                </div>
              </div>
            </v-card>
          </v-hover>
        </v-col>
      </template>
      <template v-else>
        <v-col
          cols="12"
        >
          <div class="co-flex-col co-justify-center co-items-center pa-6">
            <span
              class="co-text-md co-text-gray-500"
            >
              感谢浏览我遇见最美的光，我们正在完善内容
            </span>
          </div>
        </v-col>
      </template>
    </v-row>

    <div
      v-if="pagination.length"
      class="d-flex flex-row mt-6 mb-8"
    >
      <v-pagination
        v-model="pagination.current"
        :length="pagination.length"
        :total-visible="pagination.totalVisible"
        @input="changePagination($event)"
      />
    </div>
    <dialog-alert ref="dlgAlert" />
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  import web from '@/api/web/co.web'
  import user from '@/api/co.user'
  import mixPreset from '@/pages/mixins/mix.preset'

  const parts = mixPreset.Parts
  const names = mixPreset.Names
  const part = parts[names.ARTICLE]

  export default {
    components: {
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    props: {
      backColor: {
        type: String,
        default: 'white'
      },
      round: {
        type: String,
        default: 'rounded'
      },
      margin: {
        type: String,
        default: 'ma-0'
      },
      hovered: {
        type: Boolean,
        default: true
      },
      perColumns: {
        type: [Number, String],
        default: 3
      },
      normalElevation: {
        type: [Number, String],
        default: 0
      },
      hoverElevation: {
        type: [Number, String],
        default: 18
      }
    },
    data () {
      return {
        userInfo: {},
        name: part.name,
        label: part.label,
        title: part.title,
        total: 0,
        type: part.type,
        ratio: part.ratio,
        params: null,
        items: [],
        pagination: {
          current: 1,
          length: 0,
          totalVisible: 7
        },
        btnDel: {
          icon: 'mdi-close'
        },
        defAvatar: app.pic.avatar_user,
        handlers: {
          like: this.onLike,
          forward: this.onForward,
          comment: this.onComment
        }
      }
    },
    created () {
      this.userInfo = user.info.get()
      this.configParams()
      this.loadContents()
    },
    methods: {
      showAlert (type, message) {
        const dlg = this.$refs.dlgAlert
        if (dlg) {
          dlg.show({
            type,
            message
          })
        }
      },
      onForward (ev, index, item) {
        const me = this
        const callback = function (res) {
          if (res.status) {
            me.toContentForward(ev, index, item)
          } else {
            me.$confirm('登录后才能进行操作', '登录提示', {
              confirmButtonText: '去登录',
              cancelButtonText: '暂不',
              type: 'info'
            }).then(() => {
              me.toLogin()
            }).catch(() => {
              me.$message({
                type: 'info',
                message: '已取消登录！'
              })
            })
          } // if (res.status) else
        }
        user.login.isLogined(callback)
      },
      onLike (ev, index, item) {
        const me = this
        const callback = function (res) {
          if (res.status) {
            me.toContentLike(ev, index, item)
          } else {
            me.$confirm('登录后才能进行操作', '登录提示', {
              confirmButtonText: '去登录',
              cancelButtonText: '暂不',
              type: 'info'
            }).then(() => {
              me.toLogin()
            }).catch(() => {
              me.$message({
                type: 'info',
                message: '已取消登录！'
              })
            })
          } // if (res.status) else
        }
        user.login.isLogined(callback)
      },
      onComment (ev, index, item) {
        const me = this
        const callback = function (res) {
          if (res.status) {
            me.toContentComment(ev, index, item)
          } else {
            me.$confirm('登录后才能进行操作', '登录提示', {
              confirmButtonText: '去登录',
              cancelButtonText: '暂不',
              type: 'info'
            }).then(() => {
              me.toLogin()
            }).catch(() => {
              me.$message({
                type: 'info',
                message: '已取消登录！'
              })
            })
          } // if (res.status) else
        }
        user.login.isLogined(callback)
      },
      toContentComment (ev, index, item) {
        const me = this
        const commentContent = api.comm.trim(ev.content)
        if (commentContent) {
          const data = {
            commentContent,
            contentId: item.contentId,
            username: this.userInfo.username,
            avatar: this.userInfo.avatar
          }

          const executing = function () {}
          const executed = function (res) {
            if (res.status) {
              let value = parseInt(item.commentNum, 10) || 0
              value += 1
              me.$set(item, 'commentNum', value)
              me.showAlert('success', '评论成功')
            } else {
              const err = res.data || { code: 9000, desc: '未知错误' }
              me.showAlert('error', `${err.code}-${err.desc}`)
            }
          }
          web.comment.create({
            data,
            executing,
            executed
          })
        } else {
          this.$alert('填好评论内容后才能发布！', '提示', {
            confirmButtonText: '好的',
            type: 'warning'
          })
        }
      },
      toContentForward (ev, index, item) {
        const me = this
        const text = api.comm.trim(ev.content)
        if (text) {
          const contentId = item.contentId
          const executed = function (res) {
            // console.log({ res })
            if (res.status) {
              let value = parseInt(item.forwardedNum, 10) || 0
              value += 1
              me.$set(item, 'forwardedNum', value)
              me.showAlert('success', '转发成功')
            } else {
              const err = res.data || { code: 9000, desc: '未知错误' }
              me.showAlert('error', `${err.code}-${err.desc}`)
            }
          }

          web.behaviour.forward({
            contentId,
            forwardedText: text,
            executed
          })
        } else {
          this.$alert('填好转发后显示标题才能转发！', '提示', {
            confirmButtonText: '好的',
            type: 'warning'
          })
        }
      },
      toLikeCancel (index, item) {
        const me = this
        const executed = function (res) {
          // console.log({ res })
          if (res.status) {
            let num = parseInt(item.likesNum, 10) || 0
            if (num < 1) {
              num = 0
            } else {
              num -= 1
            }
            me.$set(item, 'likesNum', num)
            me.showAlert('success', '取消点赞成功')
          } else {
            const err = res.data || { code: 9000, desc: '未知错误' }
            me.showAlert('error', `${err.code}-${err.desc}`)
          }
        }

        web.behaviour.cancel({
          id: item.contentId,
          type: 'like',
          executed
        })
      },
      toContentLike (ev, index, item) {
        const me = this
        const executed = function (res) {
          // console.log({ res })
          if (res.status) {
            let num = parseInt(item.likesNum, 10) || 0
            if (num < 0) {
              num = 0
            }
            num += 1
            me.$set(item, 'likesNum', num)
            me.showAlert('success', '点赞成功')
          } else {
            const err = res.data || {}
            if (err.code === 1001) {
              me.toLikeCancel(index, item)
            } else {
              const err = res.data || { code: 9000, desc: '未知错误' }
              me.showAlert('error', `${err.code}-${err.desc}`)
            }
          }
        }

        web.behaviour.like({
          contentId: item.contentId,
          executed
        })
      },
      onAction (ev, index, item) {
        const handler = this.handlers[ev.name]
        if (typeof handler === 'function') {
          handler(ev, index, item)
        }
      },
      toIssue () {
        api.page.navigate(this, 'issueArticle')
      },
      toDelete (index, item) {
        const me = this
        const executed = function (res) {
          if (res.status) {
            me.items.splice(index, 1)
            me.$notify({
              title: '成功提示',
              message: '作品删除成功！',
              type: 'success'
            })
          } else {
            me.$notify({
              title: '删除失败',
              message: `${res.data.code} - ${res.data.desc}`,
              type: 'error'
            })
          }
        }

        web.content.delete({
          id: item.contentId,
          executed
        })
      },
      onDelete (index, item) {
        const me = this
        this.$confirm(
          '确定需要删除此作品吗？',
          '删除提示', {
            distinguishCancelAndClose: true,
            confirmButtonText: '确定',
            cancelButtonText: '取消'
          }
        )
          .then(() => {
            me.toDelete(index, item)
          })
          .catch(action => {})
      },
      toContent (index, item) {
        const params = {
          contentId: item.contentId,
          type: item.type,
          title: item.title
        }

        api.page.navigate(this, 'hotDetail', params)
      },
      changePagination (number) {
        const params = this.params
        const data = this.params.data
        const index = parseInt(number, 10) || 1

        this.pagination.current = index
        data.currentPage = index
        params.reload = true
        api.httpx.getItems(params)
      },
      configParams () {
        const me = this
        const executed = function (res) {
          if (res.status) {
            me.total = res.total
            me.title = `${me.label} ${res.total}`
            app.emit(app.event.MY_ARTICLES_GET, {
              name: me.name,
              title: me.title,
              label: me.label,
              total: res.total
            })
            const data = me.params.data
            const pagination = me.pagination
            const pageSize = parseInt(data.pageSize) || 9
            pagination.length = Math.ceil(res.total / pageSize)
          }
        }

        me.params = web.content.getParamsHot({
          type: me.type,
          state: web.comm.States.PUBLISH,
          pageSize: '24',
          caches: this.items,
          executed
        })
      },
      loadContents (reload = true) {
        this.params.reload = reload
        api.httpx.getItems(this.params)
      },
    }
  }
</script>
